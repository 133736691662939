/* Fonts */
@import url("https://fonts.googleapis.com/css?family=Bree+Serif|Open+Sans");

/* Header */
.navbar-brand {
    width: 200px;
}

.header-title {
    font-family: 'Bree Serif', sans-serif, Arial;
    color: #292826;
    margin-left: 10px;
    font-size: 2rem;
    padding-bottom: 5px;
}

/* .impersonate {
    background-color: #99ff99 !important;
} */

.impersonate-wrapper {
    width: 100%;
    text-align: center;
    margin-top: -10px;
}

.impersonate-wrapper a {
    width: 450px;
}

.impersonation {
    color: #FF0000;
}

@media (min-width: 768px) {
    .navbar-brand {
        width: 150px;
    }

    .header-title {
        font-size: 1.5rem;
        padding-bottom: 5px;
    }
}

@media (min-width: 992px) {
    .navbar-brand {
        width: 200px;
    }

    .header-title {
        font-size: 2.2rem;
        padding-bottom: 10px;
    }
}

.nav-link {
    padding: 0.5rem 0.7rem;
}

div.main {
    border-right: 1px solid #dee2e6;
    position: relative;
}

div.sidebar {
    height: calc(100vh - 150px);
    border-left: 1px solid #dee2e6;
    position: relative;
    left: -1px;
}

.title {
    color: rgb(227, 37, 85);
}

td.min {
    width: 1%;
    white-space: nowrap;
}

.btn-magenta {
    background-color: rgb(227, 37, 85);
    border-color: rgb(227, 37, 85);
}

.btn-magenta:hover {
    background-color: rgb(180, 30, 68);
    border-color: rgb(180, 30, 68);
}

.ribbon {
    top: 0;
    left: 0;
    width: 4rem;
    height: 4rem;
    border-bottom: solid 2rem transparent !important;
    border-left: solid 2rem rgb(227, 37, 85);
    border-right: solid 2rem transparent !important;
    border-top: solid 2rem rgb(227, 37, 85);
    position: absolute;
    z-index: 1;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.ribbon-icon {
    margin-top: -1.5rem;
    margin-left: -1.6rem;
}

/* Timeline end */


/* Password strength */
#password-strength {
    height: 5px;
    width: 100%;
    display: block;
    background-color: #ccc;
}

#password-strength span {
    display: block;
    height: 5px;
    border-radius: 2px;
    transition: all 500ms ease;
}

.strength-0 span {
    background-color: red;
    width: 5%;
}

.strength-1 span {
    background-color: orangered;
    width: 25%;
}

.strength-2 span {
    background-color: orange;
    width: 50%;
}

.strength-3 span {
    background-color: yellowgreen;
    width: 75%;
}

.strength-4 span {
    background-color: green;
    width: 100%;
}

/* Datepicker toggle*/
#datepicker-toggle {
    height: 0px;
}

#datepicker-toggle u {
    cursor: pointer;
}

/* Datepicker active day */
td.day {
    font-weight: bold;
}

td.disabled.day {
    font-weight: normal;
}